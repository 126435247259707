import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { LoadingIndicator } from "@components/LoadingIndicator";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { LegendTitle } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import React, { PropsWithChildren, useMemo } from "react";
import { useNavigate, useMatch } from "react-router-dom";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { PaymentRequestReadyStatus } from "src/context/PaymentRequestContext";
import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  verticalStackCss,
  spacing,
} from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";

enum PaymentFlowOptionsOrderVariant {
  CONTROL = "CONTROL",
  HIGH_BANK_AND_PAYPAL = "HIGH_BANK_AND_PAYPAL",
}

const PAYMENT_SELECTOR_OPTIONS_ORDER = [
  DonationFlowPaymentOption.CREDIT_CARD,
  DonationFlowPaymentOption.PAYPAL,
  DonationFlowPaymentOption.GIFT_CARD,
  DonationFlowPaymentOption.BANK,
  DonationFlowPaymentOption.CRYPTO,
  DonationFlowPaymentOption.DAF,
  DonationFlowPaymentOption.VENMO,
  DonationFlowPaymentOption.STOCKS,
  DonationFlowPaymentOption.PAYMENT_REQUEST,
];

const PAYMENT_SELECTOR_OPTIONS_ORDER_FOR_VARIANT = {
  [PaymentFlowOptionsOrderVariant.CONTROL]: PAYMENT_SELECTOR_OPTIONS_ORDER,
  [PaymentFlowOptionsOrderVariant.HIGH_BANK_AND_PAYPAL]: [
    DonationFlowPaymentOption.CREDIT_CARD,
    DonationFlowPaymentOption.BANK,
    DonationFlowPaymentOption.PAYPAL,
    DonationFlowPaymentOption.PAYMENT_REQUEST,
    DonationFlowPaymentOption.CRYPTO,
    DonationFlowPaymentOption.STOCKS,
    DonationFlowPaymentOption.DAF,
    DonationFlowPaymentOption.VENMO,
    DonationFlowPaymentOption.GIFT_CARD,
  ],
};

const sortPaymentFlowOptionsForVariant = (
  options: DonationFlowPaymentOption[],
  variant: PaymentFlowOptionsOrderVariant
) => {
  const order =
    PAYMENT_SELECTOR_OPTIONS_ORDER_FOR_VARIANT[variant] ??
    PAYMENT_SELECTOR_OPTIONS_ORDER;

  return [...options].sort((a, b) => order.indexOf(a) - order.indexOf(b));
};

const usePaymentFlowOptionsOrder = (
  options: Set<DonationFlowPaymentOption>,
  variant: PaymentFlowOptionsOrderVariant = PaymentFlowOptionsOrderVariant.CONTROL,
  isSmallScreen?: boolean
) => {
  const sortedOptions = useMemo(() => {
    if (variant === PaymentFlowOptionsOrderVariant.CONTROL && !isSmallScreen) {
      return [...options];
    }

    return sortPaymentFlowOptionsForVariant([...options], variant);
  }, [options, variant, isSmallScreen]);

  return sortedOptions;
};

const buttonDropdowsCss = css`
  white-space: nowrap;
  ${horizontalStackCss.xs};
  align-items: center;
`;

const GooglePayLabel = (
  <div
    css={[
      buttonDropdowsCss,
      css`
        svg {
          path {
            stroke: none !important;
          }
        }
      `,
    ]}
  >
    <Icon
      iconImport={() => import(`@components/Icon/icons/GoogleIcon`)}
      size={IconSize.SMALL}
      display={IconDisplay.ACCENT}
    />
    <span>Google Pay</span>
  </div>
);

const donationFlowPaymentOptionToComponentMap: {
  [key in DonationFlowPaymentOption]: {
    label: React.ReactNode;
    routeName: PaymentProcessRouteName;
    buttonCss?: SerializedStyles;
  };
} = {
  [DonationFlowPaymentOption.CREDIT_CARD]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/CardIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>Card</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.CREDIT_CARD,
  },
  [DonationFlowPaymentOption.BANK]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/BankIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>Bank</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.BANK,
  },
  [DonationFlowPaymentOption.PAYPAL]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/Paypal`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>PayPal</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.PAYPAL,
    buttonCss: css`
      &:disabled,
      &[disabled] {
        svg {
          path:nth-child(1) {
            fill: #dff0ec;
          }
          path:nth-child(2) {
            fill: white;
          }
          path:nth-child(3) {
            fill: #bfe0da;
          }
        }
      }
    `,
  },
  [DonationFlowPaymentOption.VENMO]: {
    label: (
      <div
        css={[
          buttonDropdowsCss,
          css`
            svg {
              path {
                fill: #3d95ce;
              }
            }
          `,
        ]}
      >
        <Icon
          iconImport={() => import(`@components/Icon/icons/Venmo`)}
          size={IconSize.SMALL}
          display={IconDisplay.CURRENT_COLOR}
        />
        <span>Venmo</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.VENMO,
    buttonCss: css`
      &:disabled,
      &[disabled] {
        svg {
          path {
            fill: white;
          }
        }
      }
    `,
  },
  [DonationFlowPaymentOption.GIFT_CARD]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/GiftIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>Gift card</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.GIFT_CARD,
  },
  [DonationFlowPaymentOption.PAYMENT_REQUEST]: {
    label: (
      <div
        css={[
          buttonDropdowsCss,
          css`
            svg {
              path {
                fill: var(${colorCssVars.accent.large});
              }
            }
          `,
        ]}
      >
        <Icon
          iconImport={() => import(`@components/Icon/icons/Apple`)}
          size={IconSize.SMALL}
          display={IconDisplay.CURRENT_COLOR}
        />
        <span>Apple Pay</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.APPLE_PAY,
    buttonCss: css`
      &:disabled,
      &[disabled] {
        svg {
          path {
            fill: white;
            stroke: none;
          }
        }
      }
    `,
  },
  [DonationFlowPaymentOption.CRYPTO]: {
    label: (
      <div
        css={[
          buttonDropdowsCss,
          css`
            svg {
              path {
                fill: var(${colorCssVars.accent.large});
                stroke: none;
              }
            }
          `,
        ]}
      >
        <Icon
          iconImport={() => import(`@components/Icon/icons/CryptoPrimaryIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>Crypto</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.CRYPTO,
    buttonCss: css`
      &:disabled,
      &[disabled] {
        svg {
          path {
            fill: white;
            stroke: none;
          }
        }
      }
    `,
  },
  [DonationFlowPaymentOption.STOCKS]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/StockIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>Stocks</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.STOCKS,
  },
  [DonationFlowPaymentOption.DAF]: {
    label: (
      <div css={buttonDropdowsCss}>
        <Icon
          iconImport={() => import(`@components/Icon/icons/DafLogoMarkIcon`)}
          size={IconSize.SMALL}
          display={IconDisplay.ACCENT}
        />
        <span>DAF</span>
      </div>
    ),
    routeName: PaymentProcessRouteName.DAF,
  },
};

interface SelectDonationFlowPaymentOptionProps {
  selectedPaymentOption: DonationFlowPaymentOption;
  paymentRequestReadyStatus: PaymentRequestReadyStatus;
  paymentRequestIsApplePay: boolean;
  // Able to be a Set because Javascript Sets maintain insertion order
  paymentFlowOptions: Set<DonationFlowPaymentOption>;
  showMorePaymentOptions: boolean;
  setShowMorePaymentOptions: (value: boolean) => void;
  orderTestVariant?: PaymentFlowOptionsOrderVariant;
}

const SideBar = styled.div`
  display: none;
  width: 169px;
  background-color: #eaeded;

  button:not(:last-child) {
    border-bottom: 1px solid var(${colorCssVars.background.normal});
  }

  button:first-of-type {
    padding-top: ${spacing.xs};
  }

  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: verticalStackCss.none,
  })};
`;

const StyledButton = styled(Button)`
  background: none;
  border-radius: 0;
  &:hover {
    background: #f3f6f6;
    border-bottom: 1px solid #f3f6f6 !important;
  }
  &:disabled,
  &[disabled] {
    background: var(${colorCssVars.background.normal});
    font-weight: ${FontWeight.BOLD};
  }
`;

const StyledSelectButton = styled(Button)`
  background: none;
  border: 1.5px solid #cccccc;
  border-radius: 8px;
  padding: 5px 12px;
  height: 36px;
  &:disabled,
  &[disabled] {
    background: var(${colorCssVars.accent.large});
    color: var(${colorCssVars.background.normal});
    border: 1px solid var(${colorCssVars.accent.large});
    svg {
      path {
        stroke: white;
      }
      rect {
        stroke: white;
      }
      circle {
        stroke: white;
      }
    }
  }
`;

const StyledSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing.s};
`;

const buttonContentCss = css`
  white-space: nowrap;
  padding: ${spacing.m};
  ${horizontalStackCss.xs};
  align-items: center;
`;

const selectorButtonContentCss = css`
  white-space: nowrap;
  ${horizontalStackCss.xs};
  align-items: center;
  font-size: 16px;
  p {
    display: none;
  }
`;

const PaymentOptionSelector = styled.div`
  ${verticalStackCss.s};
  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      display: none;
    `,
  })}
  padding-bottom: ${spacing.l};
`;

const SidebarSelectPaymentOptionButton = ({
  option,
  children,
  routeName,
}: PropsWithChildren<{
  option: DonationFlowPaymentOption;
  routeName: PaymentProcessRouteName;
}>) => {
  const navigate = useNavigate();
  const match = useMatch(`${paymentProcessRouteNameToPathMap[routeName]}/*`);
  return (
    <StyledButton
      role={ButtonRole.UNSTYLED}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: () => navigate(paymentProcessRouteNameToPathMap[routeName]),
      }}
      data-tname={`SelectDonationFlowPaymentOption--${option}`}
      disabled={!!match}
      size={ButtonSize.MEDIUM}
      contentCss={buttonContentCss}
    >
      {children}
    </StyledButton>
  );
};

const SelectPaymentOptionButton = ({
  option,
  children,
  routeName,
  buttonCss,
}: PropsWithChildren<{
  option: DonationFlowPaymentOption;
  routeName: PaymentProcessRouteName;
  buttonCss?: SerializedStyles;
}>) => {
  const navigate = useNavigate();
  const match = useMatch(`${paymentProcessRouteNameToPathMap[routeName]}/*`);
  return (
    <StyledSelectButton
      role={ButtonRole.UNSTYLED}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: () => navigate(paymentProcessRouteNameToPathMap[routeName]),
      }}
      data-tname={`SelectDonationFlowPaymentOption--${option}`}
      disabled={!!match}
      size={ButtonSize.MEDIUM}
      contentCss={selectorButtonContentCss}
      css={buttonCss}
    >
      {children}
    </StyledSelectButton>
  );
};

/**
 * Component to select the donation flow payment option.
 *
 * Currently using placeholder styling.
 */
const SidebarSelectPaymentOption = ({
  paymentFlowOptions,
  paymentRequestReadyStatus,
  paymentRequestIsApplePay,
  orderTestVariant,
}: SelectDonationFlowPaymentOptionProps) => {
  const sortedOptions = usePaymentFlowOptionsOrder(
    paymentFlowOptions,
    orderTestVariant,
    false
  );
  return (
    <SideBar>
      {sortedOptions.map((option) => {
        const component = donationFlowPaymentOptionToComponentMap[option];
        if (!component) {
          return null;
        }
        let label: React.ReactNode = component.label;
        if (option === DonationFlowPaymentOption.PAYMENT_REQUEST) {
          if (paymentRequestReadyStatus === PaymentRequestReadyStatus.UNABLE) {
            return null;
          }
          if (
            paymentRequestReadyStatus ===
            PaymentRequestReadyStatus.UNINITIALIZED
          ) {
            return (
              <SidebarSelectPaymentOptionButton
                key={option}
                option={option}
                routeName={component.routeName}
              >
                <LoadingIndicator />
              </SidebarSelectPaymentOptionButton>
            );
          }
          if (!paymentRequestIsApplePay) {
            label = GooglePayLabel;
          }
        }
        return (
          <SidebarSelectPaymentOptionButton
            key={option}
            option={option}
            routeName={component.routeName}
          >
            {label}
          </SidebarSelectPaymentOptionButton>
        );
      })}
    </SideBar>
  );
};

const ApplePayButton = ({
  selectedPaymentOption,
  paymentRequestReadyStatus,
  paymentRequestIsApplePay,
}: SelectDonationFlowPaymentOptionProps) => {
  const applePayBtnData =
    donationFlowPaymentOptionToComponentMap[
      DonationFlowPaymentOption.PAYMENT_REQUEST
    ];
  let label: React.ReactNode = applePayBtnData.label;
  if (paymentRequestReadyStatus === PaymentRequestReadyStatus.UNABLE) {
    return null;
  }
  if (paymentRequestReadyStatus === PaymentRequestReadyStatus.UNINITIALIZED) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          height: 36px;
          padding: 10px 0;
        `}
      >
        <LoadingIndicator />
      </div>
    );
  }
  if (!paymentRequestIsApplePay) {
    label = GooglePayLabel;
  }

  return (
    <SelectPaymentOptionButton
      key={DonationFlowPaymentOption.PAYMENT_REQUEST}
      option={DonationFlowPaymentOption.PAYMENT_REQUEST}
      routeName={applePayBtnData.routeName}
      buttonCss={applePayBtnData.buttonCss}
    >
      {label}
    </SelectPaymentOptionButton>
  );
};

const PAYMENT_METHODS_TO_SHOW_SIZE = 4;

const SelectorPaymentOptionNew = ({
  selectedPaymentOption,
  paymentRequestReadyStatus,
  paymentRequestIsApplePay,
  paymentFlowOptions,
  showMorePaymentOptions,
  setShowMorePaymentOptions,
  orderTestVariant,
}: SelectDonationFlowPaymentOptionProps) => {
  const sortedOptions = usePaymentFlowOptionsOrder(
    paymentFlowOptions,
    orderTestVariant,
    true
  );

  let paymentOptionsToShow = sortedOptions;

  if (!showMorePaymentOptions) {
    const paymentOptionsSubset = paymentOptionsToShow
      .filter((v) => v !== DonationFlowPaymentOption.PAYMENT_REQUEST)
      .slice(0, PAYMENT_METHODS_TO_SHOW_SIZE);
    if (
      selectedPaymentOption === DonationFlowPaymentOption.PAYMENT_REQUEST ||
      paymentOptionsSubset.includes(selectedPaymentOption)
    ) {
      paymentOptionsToShow = paymentOptionsSubset;
    }
  }

  if (
    paymentOptionsToShow.length > PAYMENT_METHODS_TO_SHOW_SIZE &&
    !showMorePaymentOptions
  ) {
    setShowMorePaymentOptions(true);
  }

  return (
    <PaymentOptionSelector>
      <LegendTitle>Payment method</LegendTitle>
      {paymentFlowOptions.has(DonationFlowPaymentOption.PAYMENT_REQUEST) && (
        <ApplePayButton
          paymentRequestReadyStatus={paymentRequestReadyStatus}
          paymentRequestIsApplePay={paymentRequestIsApplePay}
          selectedPaymentOption={selectedPaymentOption}
          paymentFlowOptions={paymentFlowOptions}
          setShowMorePaymentOptions={setShowMorePaymentOptions}
          showMorePaymentOptions={showMorePaymentOptions}
        />
      )}
      <StyledSelectWrapper>
        {paymentOptionsToShow.map((option) => {
          const component = donationFlowPaymentOptionToComponentMap[option];
          if (!component) {
            return null;
          }
          if (option === DonationFlowPaymentOption.PAYMENT_REQUEST) {
            return null;
          }
          return (
            <SelectPaymentOptionButton
              key={option}
              option={option}
              routeName={component.routeName}
              buttonCss={component.buttonCss}
            >
              {component.label}
            </SelectPaymentOptionButton>
          );
        })}
      </StyledSelectWrapper>
      {paymentFlowOptions.size > PAYMENT_METHODS_TO_SHOW_SIZE &&
        !showMorePaymentOptions && (
          <Button
            data-tname="seeMorePaymentsButton"
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                setShowMorePaymentOptions(!showMorePaymentOptions);
              },
            }}
          >
            More options
          </Button>
        )}
    </PaymentOptionSelector>
  );
};

export const SelectDonationFlowPaymentOption = (
  props: SelectDonationFlowPaymentOptionProps
) => {
  const skip = props.paymentFlowOptions.size === 1;

  const orderTestVariant = useStatSigLayer("payment_methods_order", skip)?.get<
    PaymentFlowOptionsOrderVariant | undefined
  >("order", undefined);

  if (skip) {
    return null;
  }

  return (
    <div
      css={css`
        background-color: none;
        ${cssForMediaSize({
          min: MediaSize.X_LARGE,
          css: css`
            background-color: #eaeded;
          `,
        })}
      `}
    >
      <SidebarSelectPaymentOption
        {...props}
        orderTestVariant={orderTestVariant}
      />
      <SelectorPaymentOptionNew
        {...props}
        orderTestVariant={orderTestVariant}
      />
    </div>
  );
};
